.App {
    text-align: center;
  }

  .listName{
    margin-top: 8px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
  }

  .headline{
    margin-top: 5px;
    text-align: left;
  }

  .Wrapper{
    margin: 5px 0 0 0;
    padding: 5px 15px;
  }

  span{
    font-size: 1rem;
  }

  h6 span{
    display: inline-block;
  }

  p {
    font-size: 0.8rem;
  }